import { _axiosapi } from '../_axios';
export const totalWriteOff = (params ) =>{
    return _axiosapi({
        url:'/mall/writeOff/record/totalWriteOff',
        method:'POST',
        data:{request:params}
    })
}
export const recordList = (parmas) =>{
    return _axiosapi({
        url:'/mall/writeOff/record/recordList',
        method:'POST',
        data:{request:parmas}
    })
}
export const writeOffDetails = (params) =>{
    return _axiosapi({
        url:'/mall/writeOff/record/writeOffDetails',
        method:'POST',
        data:{request:params}
    })
}