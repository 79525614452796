<template>
  <div class="detail">
    <top title="直付详情"></top>
    <div class="img_box">
      <img class="detail_img" v-if="info.coverImg" :src="info.coverImg[0].url" alt="">
    </div>
    <div class="detail_box">
      <div class="box_headers">
        <div class="store">{{info.pharmacyName}}({{info.pharmacyCode}})</div>
        <div class="price" :style="[{color:(status==2?'red':'#518E37')}]">{{status==1?'-':'+'}}￥{{info.total}}</div>
         <div class="abs abs_left"></div>
         <div class="abs abs_right"></div>
      </div>
      <div class="box_center">
        <div class="center_item">
          <span>状态</span>
          <span >{{status == 0?'待直付':status == 1?'已直付':status == 2?'已退款':'已取消'}}</span>
        </div>
        <div class="center_item">
          <span>药店名称</span>
          <span>{{info.pharmacyName}}</span>
        </div>
        <div class="center_item">
          <span>商保金额</span>
          <span>¥{{info.insuranceAmount}}</span>
        </div>
        <div class="center_item">
          <span>特效药金额</span>
          <span>¥{{info.specialDrugAmount}}</span>
        </div>
        <div class="center_item">
          <span>自付金额</span>
          <span>¥{{info.selfPayAmount}}</span>
        </div>
        <div class="center_item">
          <span>直付时间</span>
          <span>{{info.createTime}}</span>
        </div>
        <div class="center_item">
          <span>直付方式</span>
          <span>直付服务卡</span>
        </div>
        <div class="center_item">
          <span>订单号</span>
          <div>
          <span id="success_form_input">{{info.orderNo}} </span>  
            <span class="copy" data-clipboard-action="copy" data-clipboard-target="#success_form_input" ref="copy_btn"  @click="copy">复制</span>
          </div>
          
        </div>
        <div class="center_item" style="margin-bottom:0">
          <span>卡号</span>
          <span>{{info.cardNumber}}</span>
        </div>
         <div class="abs abs_left"></div>
         <div class="abs abs_right"></div>
      </div>
      <div class="box_footer">
        <div v-for="(item,index) in info.medicineOrderVos" :key="index">
          <div class="footer_item">
            <div class="item_text">{{item.medicineName}}({{item.medicineCode}})</div>
            <div class="item_price">
              <span>¥{{item.medicinePrice}}</span>
              <div>*{{item.medicineNum}}</div>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { writeOffDetails } from '@/api/_api/cancel';
export default {
  data() {
    return {
      str:'复制成功',
      orderNo:'',
      info:{},
      copyBtn:null,
      status:''
    };
  },
  methods: {
    copy(){
      let _this = this;
      let clipboard = _this.copyBtn;
      clipboard.on('success', function() {
        _this.$toast('复制成功')
      });
    },
    init(orderNo){
      writeOffDetails({orderNo}).then(res=>{
        if(res.code == 200){
          this.info = res.data;
          res.data.coverImg = res.data.coverImg?JSON.parse(res.data.coverImg):''
        }
      })
    }
  },
  mounted(){
    this.copyBtn = new this.clipboard(this.$refs.copy_btn);
    let {orderNo} = this.$route.query;
    this.orderNo = orderNo
    this.init(orderNo)
    this.status=this.$route.query.status
    
  }
};
</script>

<style lang="scss" scoped>
.detail {
  background: #019CF4 ;
  min-height: 100vh;
  padding-bottom: vw(48);
  .detail_img{
    width:100%
  }
  .img_box{
    background: #fff;
    width: vw(120);
    height: vw(120);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    margin-top:vw(32)
  }
  .detail_box{
    background: #fff;
    margin: vw(78) vw(24) 0;
    border-radius: vw(8);
  }
  .box_headers{
    position: relative;
    padding: vw(96) 0 vw(30);
    text-align: center;
    border-bottom: 1px solid #f4f4f4;
    .store{
      font-size: vw(32);
      font-weight: 600;
      color: #333;
      margin-bottom: vw(12);
    }
    .price{
      font-size: vw(64);
      color: #518E37;
      font-weight: 700;
    }
  }
  .box_center{
    position: relative;
    padding: vw(32) vw(28);
    font-size: vw(28);
    color: #333;
    border-bottom: 1px solid #f4f4f4;
    .center_item{
      display: flex;
      justify-content: space-between;
      margin-bottom: vw(24);
    }
    .copy{
      font-size: vw(20);
      padding: vw(2) vw(10);
      border: 1px solid #019CF4 ;
      color: #019CF4 ;
      border-radius: vw(24);
    }
  }
  .box_footer{
    padding: vw(4) vw(28) vw(32);
    .footer_item{
      padding: vw(28);
      border-bottom: 1px solid #f4f4f4;
    }
    .item_text{
      width:vw(556);
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box; 
      -webkit-box-orient:vertical;
      -webkit-line-clamp:2; 
    }
    .item_price{
      display: flex;
      justify-content: space-between;
      font-size: vw(24);
      margin-top: vw(8);
        span:first-child{
          color: #FFA565;
          font-weight: 500;
        }
    }
  }
  .abs{
    position: absolute;
    width: vw(32);
    height: vw(32);
    background: #019cf4;
    border-radius: 50%;
    bottom: vw(-18)
  }
  .abs_left{
    left:vw(-16)
  }
  .abs_right{
    right:vw(-16)
  }
}
</style>
